body {
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
}

.footer a:visited {
  color: black;
}

.footer a:link {
  color: black;
}

.contentHolder{
  overflow-y: hidden;
  overflow-x: hidden;
}

.privacyPolicyBlock {
  margin: 20px;
}

.router {
  padding-top: 30px;
}

.supportBlock {
  margin: 20px;
}

.homeScreen {
  text-align: center;
}

.topNavLeft {
  float: left;
  margin-right: 20px;
  margin-top: 5px;
}

.downloadButton {
  float: right;
  padding: 5px 10px;
  margin: 5px 30px;
  background-color: #5DBCD7;
  border-radius: 20px;
  font-weight: 500;
  font-size: small;
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 1068px){
.downloadButtonMain {
  margin-top: 42px;
  padding: 18px 31px;
  background-color: #5DBCD7;
  border-radius: 30px;
  font-weight: 500;
  font-size: large;
  color: white;
  text-decoration: none;
}
}
@media screen and (min-width: 1068px){
.downloadButtonMain {
  margin-top: 34px;
  padding: 18px 31px;
  background-color: #5DBCD7;
  border-radius: 30px;
  font-weight: 500;
  font-size: large;
  color: white;
  text-decoration: none;
}
}

.topNav {
  z-index: 10;
  width: 100%;
  position: fixed;
  display: inline;
  align-items: center;
  text-align: center;
  margin: 20px;
}

.topNav img{
  height: 30px;
}

.homeLogo {
  width: 80px;
  height: 80px;
  margin-top: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}

.screenshots {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
@media screen and (max-width: 1068px) {
.screenshots img {
  margin-left: 13px;
  margin-right: 13px;
  position: relative;
  width: 220px;
}
}

@media screen and (max-width: 734px) and (max-width: 734px){
  .screenshots img {
    margin-left: 9px;
    margin-right: 9px;
    position: relative;
    width: 176px;
  }
}
  
@media screen and (min-width: 1068px){
.screenshots img {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  width: 268px;
}
}

@media screen and (max-width: 1068px) {
.homeHeader {
  display: flex;
  justify-content: center;
  line-height: 1.0625;
  font-weight: 700;
  letter-spacing: -0.009em;
  color: black;
  margin-bottom: 20px;
}
}

.cards .card-copy {
  font-size: 19px;
  line-height: 1.2105263158;
  font-weight: 600;
  letter-spacing: .012em;
  color: #86868b;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.cards .card-copy strong {
  font-weight: inherit;
  color: #1d1d1f;
}

@media screen and (max-width: 734px){
.cards .card {
  padding-top: 20px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 0px;
}
}
@media screen and (max-width: 1068px) and (min-width: 734px){
  .cards .card {
    padding-top: 50px;
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 0px;
}
}

@media screen and (min-width: 1068px){
  .cards .card {
    padding-top: 60px;
    padding-left: 46px;
    padding-right: 46px;
    padding-bottom: 0px;
    position: relative;
}
}
/*
.venueCard {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: left;
  align-items: center;
  color: white;
  box-sizing: border-box;
  border-radius: 12px;
  width: 370px;
  height: 120px;
  overflow: hidden;
  margin-right: 10px;
  margin-top: 0%;
  margin-bottom: 0%;
}

.venueCard img {
  top: 50%;
  left: 50%;
  width: 370px;
}

.venueCardText {
  position: absolute;
  top: 10px;
  left: 18px;
}

.venue-showcase {
  display: flexbox;
  justify-content: center;
}

.venue-showcase ul {
  position: relative;
  list-style: none;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.venue-showcase li {
  position: relative;
  list-style: none;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.venueRowOne {
  margin-bottom: 0%;
}

.venueRowTwo {
  margin-left: -185px;
}

.venueName {
  font-size: 24px;
  line-height: 1.2105263158;
  font-weight: 600;
  letter-spacing: .012em;
  color: white;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -12px;
}

.venueLocatedIn {
  font-size: 14px;
  line-height: 1.2105263158;
  letter-spacing: .012em;
  color: white;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -12px;
}
*/
.card {
  background: #fff;
  border-radius: 18px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 0;
  border-bottom: 0;
}

@media screen and (max-width: 720px) {
  .card img {
  width: 50%;
  height: 294px;
  object-fit: cover;
  object-position: 100% 0;
  margin: 0;
  padding: 0;
  }
  }
  
  @media screen and (max-width: 480px){
    .card img {
      width: 70%;
  height: 294px;
  object-fit: cover;
  object-position: 100% 0;
  margin: 0;
  padding: 0;
    }
  }
    
  @media screen and (min-width: 720px){
  .card img {
  width: 300px;
  height: 294px;
  object-fit: cover;
  object-position: 100% 0;
  margin: 0;
  padding: 0;
  }
  }

.cards {
  margin-left: auto;
  margin-right: auto;
  width: 87.5%;
}

.cards-Container{
  background: #f5f5f7;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 30px;
}

.footer {
  padding-bottom: 90px;
  text-align: center;
  bottom: 0;
  left: 0;
  height: 90px;
  width: 100%;
  box-sizing: content-box;
  background: linear-gradient(#f5f5f7, #fff)
  
}

.footerLinks {
    display: inline-flex;
    padding:0;
    list-style:none;
    text-align:center;
    font-size:14px;
    line-height:1.6;
    margin-bottom:0;
}

.footer p {
  text-align:center;
    font-size:10px;
    line-height:1.6;
}

.footerLinks li {
  padding: 30px;
}
